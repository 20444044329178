@import 'variables';


.basic-score {
  text-align: center;
  padding: $unit;
  
  .basic-score-title {
    font-weight: bold;
    font-size: smaller;
    text-transform: uppercase;
    color: black;
  }
}