@import 'variables';

.list { 
  margin-bottom: $unit * 4;
  
  .list-item {
    margin-bottom: $unit * 2;
  }

  .list-field-name {
    font-size: 11px;
  }

  .list-field-value {
    a {
      word-break: break-all;
    }
  }
}

