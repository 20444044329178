@import 'variables';

.facilities {
  margin-bottom: $unit * 3;
  
  .facilities-header {
    text-align: center;
  }
}

.facility {
  display: block;
  cursor: pointer;
  font-size: small;
  border-bottom: 2px solid lightgray;
  padding-bottom: 5px;
  padding-top: 5px;
  
  .facility-title::before {
    content: "▶";
    margin-right: 3px;
  }
  
  input {
    display: none;
  }
  
  .facility-params {
    display: none;
  }
  input:checked + .facility-title::before {
    content: "▼";
  }
  input:checked ~ .facility-params {
    display: block;
  }
  
  .facility-param {
    display: flex;
    justify-content: space-between;
    margin-bottom: $unit;
    margin-top: $unit;
  }
}