@import 'variables';

.product {
  max-width: 90vw;
  margin-bottom: $unit * 2;
  
  .product-main-info {
    text-align: center;
  }

  .product-name {
    margin: 0;
    margin-bottom: $unit / 2;
    
    &::first-letter {
      text-transform: uppercase;
    }
  }
  
  .product-description::first-letter {
    text-transform: uppercase;
  }

  .product-photo {
    width: 100%;
    max-width: 100%;
    margin-bottom: $unit;
  }
}