@import 'styles/variables';
@import 'styles/flex';
@import 'styles/animate';

@import 'styles/loader';
@import 'styles/product';
@import 'styles/landing-picture';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  background: $background;
  color: $font-color;
  min-height: min-content;
  font-family: sans-serif;
}

a {
  color: $font-color;

  &:visited {
    color: $font-color;
  }
}

footer {
  font-size: small;
  margin-top: $unit * 2;
  margin-bottom: $unit;
  opacity: .5;
}

.hidden {
  display: none;
}