@import 'variables';

[flex-row] {
  display: flex;
  flex-direction: row;
}

[flex-column] {
  display: flex;
  flex-direction: column;
}

[flex-center] {
  align-items: center;
  justify-content: center;
}

[flex-padding] > * {
  margin: $unit;
}

[flex] {
  flex: 1;
}