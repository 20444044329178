@import 'variables';

$icon-size: 80px;
$duration: .5s;

// << Base
.loader {
  position: relative;
  width: $icon-size;
  height: $icon-size;
  align-self: center;
}

.loader .loader-part {
  position: absolute;
  top: 25%;
  left: 25%;
  opacity: 0;
  width: 50%;
  height: 50%;
  background-color: transparent;
  transition: all $duration ease;
  transform-origin: 50% 50%;
}
// Base >>

// << Pin
.loader.pin .loader-part- {
  &0 {
    background-color: $foreground;
    opacity: 1;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    transform: rotate(-45deg);
    border-radius: 50% 50% 50% 0;
  }

  &1 {
    opacity: 1;
    background-color: $background;
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    border-radius: 100%;
  }
}
// Pin >>

// << Wi-fi
.loader.wi-fi .loader-part- {
  &0 {
    background-color: $foreground;
    opacity: 1;
    top: 80%;
    left: 42.5%;
    width: 15%;
    height: 15%;
    border-radius: 50%;
    transition-duration: $duration * 1.2;
  }

  &1 {
    opacity: 1;
    top: 10%;
    left: 20%;
    width: 60%;
    height: 60%;
    border-radius: 100% 0 0 0;
    transform: rotate(45deg) scale(1.4);
    background: radial-gradient(circle at bottom right, transparent 0%, transparent 30%, $foreground 30%, $foreground 36%, transparent 35%, transparent 40%, $foreground 41%, $foreground 46%, transparent 45%, transparent 50%, $foreground 51%, $foreground 56%, transparent 55%, transparent 100%);
  }
}
// Wi-fi >>

// << T-shirt
.loader.t-shirt .loader-part- {
  &0 {
    background: radial-gradient(circle at top center, transparent 0%, transparent 15%, $foreground 16%, $foreground 100%);
    opacity: 1;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 60%;
  }

  &2 {
    background-color: $foreground;
    opacity: 1;
    transform-origin: top left;
    top: 30%;
    left: 30%;
    width: 10%;
    height: 30%;
    transform: rotate(30deg);
    transition-duration: $duration * 1.2;
  }

  &3 {
    background-color: $foreground;
    opacity: 1;
    transform-origin: top right;
    top: 30%;
    left: 60%;
    width: 10%;
    height: 30%;
    transform: rotate(-30deg);
    transition-duration: $duration * 1.2;
  }
}
// T-shirt >>

// << Fridge
.loader.fridge .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 10%;
    left: 30%;
    width: 40%;
    height: 30%;
    border-radius: 5px 5px 0 0;
    transition-duration: $duration * 1.2;
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 45%;
    left: 30%;
    width: 40%;
    height: 50%;
    border-radius: 0 0 5px 5px;
  }

  &2 {
    opacity: 1;
    background-color: $foreground;
    top: 95%;
    left: 35%;
    width: 5%;
    height: 5%;
    border-radius: 0 0 5px 5px;
  }

  &3 {
    opacity: 1;
    background-color: $foreground;
    top: 95%;
    left: 60%;
    width: 5%;
    height: 5%;
    border-radius: 0 0 5px 5px;
  }

  &4 {
    opacity: 1;
    background-color: $background;
    top: 20%;
    left: 35%;
    width: 2%;
    height: 10%;
    border-radius: 5px;
    transition-duration: $duration / 4;
  }

  &5 {
    opacity: 1;
    background-color: $background;
    top: 60%;
    left: 35%;
    width: 2%;
    height: 16%;
    border-radius: 5px;
    transition-duration: $duration / 4;
  }
}
// Fridge >>

// << Heart
.loader.heart .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 10%;
    left: 30%;
    width: 40%;
    height: 40%;
    border-radius: 50% 50% 0 0;
    transform: translateX(50%) rotate(45deg);
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 10%;
    left: 30%;
    width: 40%;
    height: 40%;
    border-radius: 50% 50% 0 0;
    transform: translateX(-50%) rotate(-45deg);
  }

  &2 {
    opacity: 1;
    background-color: $foreground;
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
    transform: rotate(45deg);
  }
}
// Heart >>


// << Bulb
.loader.bulb .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 10%;
    left: 30%;
    width: 40%;
    height: 40%;
    border-radius: 100%;
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 35.5%;
    left: 28%;

    width: 44.1%;
    height: 30%;
    transform: perspective(10px) rotateX(-4deg) scale(.65);
  }

  &2 {
    opacity: 1;
    background-color: $foreground;

    top: 61%;
    left: 40%;

    width: 20%;
    height: 4%;
  }

  &3 {
    opacity: 1;
    background-color: $foreground;

    top: 67%;
    left: 40%;

    width: 20%;
    height: 4%;
  }

  &4 {
    opacity: 1;
    background-color: $foreground;

    top: 73%;
    left: 45%;

    width: 10%;
    height: 3%;
  }
}
// Bulb >>

// << Gym
.loader.gym .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 45%;
    left: 25%;
    width: 50%;
    height: 10%;
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 30%;
    left: 13%;
    width: 10%;
    height: 40%;

    border-radius: 1000px;
    transition-duration: $duration * .8;
  }

  &2 {
    opacity: 1;
    background-color: $foreground;
    top: 30%;
    left: 77%;
    width: 10%;
    height: 40%;

    border-radius: 1000px;
    transition-duration: $duration * .8;
  }

  &3 {
    opacity: 1;
    background-color: $foreground;
    top: 35%;
    left: 0%;
    width: 10%;
    height: 30%;

    border-radius: 1000px;
    transition-duration: $duration * 1.2;
  }

  &4 {
    opacity: 1;
    background-color: $foreground;
    top: 35%;
    left: 89%;
    width: 10%;
    height: 30%;

    border-radius: 1000px;
    transition-duration: $duration * 1.2;
  }
}
// Gym >>

// << Camera
.loader.camera .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 25%;
    left: 15%;
    width: 70%;
    height: 40%;
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 20%;
    left: 30%;
    width: 40%;
    height: 15%;

    border-radius: 1000px;
    transform: scale(1, 1.2);
  }

  &2 {
    opacity: 1;
    background-color: $background;
    top: 25%;
    left: 32.5%;
    width: 35%;
    height: 35%;

    border-radius: 1000px;
  }

  &3 {
    opacity: 1;
    background-color: $foreground;
    top: 30%;
    left: 37.5%;
    width: 25%;
    height: 25%;

    border-radius: 1000px;
  }
}
// Camera >>

// << Smartphone
.loader.smartphone .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 0;
    left: 27.5%;
    width: 45%;
    height: 80%;
  }

  &1 {
    opacity: 1;
    background-color: $background;
    top: 5%;
    left: 30%;
    width: 40%;
    height: 62.5%;
  }

  &2 {
    opacity: 1;
    background-color: $background;
    top: 70%;
    left: 47%;
    width: 6%;
    height: 6%;

    border-radius: 1000px;
  }

  &3 {
    opacity: 1;
    background-color: $background;
    top: 2%;
    left: 45%;
    width: 10%;
    height: 1%;

    border-radius: 1000px;
  }
}
// Smartphone >>

// << Cloud
.loader.cloud .loader-part- {
  &0 {
    opacity: 1;
    background-color: $foreground;
    top: 50%;
    left: 10%;
    width: 80%;
    height: 30%;

    border-radius: 1000px;
  }

  &1 {
    opacity: 1;
    background-color: $foreground;
    top: 40%;
    left: 20%;
    width: 20%;
    height: 20%;

    border-radius: 1000px;
  }

  &2 {
    opacity: 1;
    background-color: $foreground;
    top: 26%;
    left: 32%;
    width: 30%;
    height: 30%;

    border-radius: 1000px;
  }

  &3 {
    opacity: 1;
    background-color: $foreground;
    top: 35%;
    left: 53%;
    width: 25%;
    height: 25%;

    transition-duration: $duration * 1.2;
    border-radius: 1000px;
  }
}
// Cloud >>

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}